import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';

import { TrainingProfileEditor } from '../components/TrainingProfile/TrainingProfileEditor';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';

export const loader = async (action: ClientLoaderFunctionArgs) => {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const resp = await tokenWithRedirect(
    () => apiService.trainingProfile.getProfile(id),
    action.request.url,
    { admin: true }
  );

  return {
    profile: resp.data.profile,
  };
};

export function Component() {
  const { profile } = useLoaderData<typeof loader>();
  const navigate = useNavigate();
  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />

      <TrainingProfileEditor
        profile={profile}
        onSave={() => navigate('..')}
        onCancel={() => navigate('..')}
      />
    </AdminView>
  );
}
